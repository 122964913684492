define("admin/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    time: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    },
    date: {
      hhmmss: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    },
    number: {
      CZK: {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      ISK: {
        style: 'currency',
        currency: 'ISK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      EUR: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
      },
      USD: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
      },
      FOK: {
        style: 'currency',
        currency: 'FOK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      DKK: {
        style: 'currency',
        currency: 'DKK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }
    }
  };
  _exports.default = _default;
});